import { createApp } from 'vue'
import VueCesium from 'vue-cesium'
import 'vue-cesium/dist/index.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './styles/index.scss' // 全局缺省样式
import dayjs from "dayjs";
import VueGridLayout from 'vue-grid-layout'
import qs from 'qs'
import dataV from '@jiaminghi/data-view'
import * as echarts from 'echarts';

axios.defaults.withCredentials = false;
// axios.defaults.baseURL='https://www.peteralbus.com:8087/'
axios.defaults.baseURL = 'https://www.zouran.top/api/'
const baseFileUrl = 'https://www.file.zouran.top/'
// axios.defaults.baseURL='http://localhost:8081/'
const app = createApp(App)
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$qs = qs;
app.config.globalProperties.$echarts = echarts;
app.use(ElementPlus)
app.use(dataV)
app.use(VueCesium, {
    cesiumPath: 'https://cesium.com/downloads/cesiumjs/releases/1.91/Build/Cesium/Cesium.js',
    // cesiumPath: 'https://www.files.zouran.top/Cesium/Cesium.js',
    accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5MWVhZmFlYS1mYWU2LTQ2ZDQtOTk5ZS03ZGE3MjQyM2FlODYiLCJpZCI6NjE3MjEsImlhdCI6MTYyNjMxMzk3NX0.bdlFAJG5w5iqwdFiFxPfW_h4H5nElCoyqIY0WjUcBvU'
})

app.use(VueAxios, axios).use(router).use(VueGridLayout).mount('#app')
app.config.devtools = true
