import { createRouter, createWebHistory } from 'vue-router'
// const Cesium = () => import('../views/Cesium')
import FlightPath from "@/views/FlightPath.vue";
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
    // component: () => import('@/views/Cesium.vue'),
  },
  {
    path: '/test2',
    name: 'test2',
    component: () => import('@/views/test2.vue'),
    // component: () => import('@/views/Cesium.vue'),
  },
  {
    path: '/Rescue_forces',
    name: 'Rescue_forces',
    component: () => import('@/views/Rescue_forces'),
    // component: () => import('@/views/Cesium.vue'),
  },

  {
    path: '/flightPath',
    name: 'FlightPath',
    component: FlightPath
  },
  {
    path: '/sichuan',
    name: 'sichuan',
    component: () => import('@/views/sichuan/index.vue'),
    // component: () => import('@/views/Cesium.vue'),
  },
  {
    path: '/xinjiang',
    name: 'xinjiang',
    component: () => import('@/views/xinjiang/index.vue'),
    // component: () => import('@/views/Cesium.vue'),
  },
  
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
